import React, { useState } from 'react';
import { Link, graphql } from 'gatsby';
import Layout from '../../components/Layout';
import ReviewsSection from '../../components/ReviewsSection';
import Faq from '../../components/Faq';
import ForthcomingCourses from '../../components/ForthcomingCourses';

import IconWhatsApp from '../../assets/images/svg/whatsapp.inline.svg';
import IconArrowRight from '../../assets/images/svg/arrow-right.inline.svg';
import IconArrowRightRed from '../../assets/images/svg/arrow-right-red.inline.svg';
import iconCalendar from '../../assets/images/svg/calendar.svg';
import iconClock from '../../assets/images/svg/clock.svg';
import iconTick from '../../assets/images/svg/tick.svg';
import iconTick2 from '../../assets/images/svg/tick--lg.svg';

import imgBenefit1 from '../../assets/images/classes-de-ingles/Aprende en pequeños grupos.svg';
import imgBenefit2 from '../../assets/images/classes-de-ingles/Sin tasas de matriculación ni cargos ocultos .svg';
import imgBenefit3 from '../../assets/images/classes-de-ingles/Clases variadas y divertidas .svg';
import imgBenefit5 from '../../assets/images/classes-de-ingles/Una súper mezcla de culturas .svg';
import imgReview from '../../assets/images/classes-de-ingles/Photo-Review.jpeg';
import iconQuotes from '../../assets/images/trinity-cert-tesol-course-page/quotes.svg';
import imgDots from '../../assets/images/trinity-cert-tesol-course-page/Dots.svg';
import imgCovid from '../../assets/images/classes-de-ingles/Photo Covid.jpg';

import '../../assets/styles/pages/classes-de-ingles-page.scss';
import { FormattedMessage } from 'react-intl';

const faqs = [
  {
    id: 1,
    title: '¿Cómo me inscribo?',
    description:
      'Recomendamos un mínimo de 4 meses seguidos del mismo nivel antes de subir. Los tutores del curso te informarán si puedes subir de nivel.',
  },
  {
    id: 2,
    title: '¿Dónde se imparten las clases?',
    description: `
      <strong>Barcelona:</strong> <br/>Carrer d’Aragó 403,
Entresuelo 1º
Barcelona, 08013
Spain
    `,
  },
  {
    id: 4,
    title: '¿Cuánto tiempo dura el curso?',
    description:
      'Puedes estudiar todo el tiempo que quieras. Cada mes trabajamos con un grupo nuevo de profesores, por lo tanto los profesores van cambiando cada mes pero seguimos un plan de estudios, para que haya continuidad para los alumnos que estudian con nosotros durante mucho tiempo.',
  },
  {
    id: 5,
    title: '¿Puedo subir de nivel?',
    description:
      'Recomendamos un mínimo de 4 meses seguidos del mismo nivel antes de subir. Los tutores del curso te informarán si puedes subir de nivel.',
  },
];

const beginner = `
                <ul class='faq-list'>
                    <li class='faq-list__item'>Introduce yourself and meet other people.</li>
                    <li class='faq-list__item'>Describe people, objects, places, situations or weather.</li>
                    <li class='faq-list__item'>Express your likes and dislikes.</li>
                    <li class='faq-list__item'>Describe your family and friends.</li>
                    <li class='faq-list__item'>Describe the market, clothes and shopping.</li>
                    <li class='faq-list__item'>Make suggestions.</li>
                    <li class='faq-list__item'>Time, dates, numbers.</li>
                    <li class='faq-list__item'>Describe the seasons.</li>
                    <li class='faq-list__item'>Describe transport, cities, countries.</li>
                    <li class='faq-list__item'>Describe possessions.</li>
                </ul>
                `;
const loverIntermediate = `
                <ul class='faq-list'>
                <li class='faq-list__item'>Describe routines and habits.</li>
                <li class='faq-list__item'>Describe and narrate experiences and events in the past.</li>
                <li class='faq-list__item'>Discuss diet and lifestyle.</li>
                <li class='faq-list__item'>Use polite forms and give advice.</li>
                <li class='faq-list__item'>Talk about the future and plans.</li>
                <li class='faq-list__item'>Give directions and instructions.</li>
                <li class='faq-list__item'>Describe appearances.</li>
                </ul>
                `;
const intermediate = `
                <ul class='faq-list'>
                <li class='faq-list__item'>Describe experiences, anecdotes, stories and tales of the past.</li>
                <li class='faq-list__item'>Express your desires and feelings.</li>
                <li class='faq-list__item'>Talk about hypothetical situations.</li>
                <li class='faq-list__item'>Discuss fictional stories.</li>
                <li class='faq-list__item'>Express and justify your opinions.</li>
                <li class='faq-list__item'>Describe emotions and give advice.</li>
                <li class='faq-list__item'>Describe and respond to the news and current events.</li>
                <li class='faq-list__item'>Describe achievements.</li>
                <li class='faq-list__item'>Discuss the environment and social issues.</li>
                </ul>
                `;
const upperIntermediate = `
                <ul class='faq-list'>
                <li class='faq-list__item'>Participate in discussions and describe your viewpoint.</li>
                <li class='faq-list__item'>Retell stories in other peoples’ words.</li>
                <li class='faq-list__item'>Express hypothetical conditions.</li>
                <li class='faq-list__item'>Give detailed and accurate explanations of preferences, descriptions, personal experiences.</li>
                <li class='faq-list__item'>Tell anecdotes.</li>
                <li class='faq-list__item'>Describe wishes and regrets.</li>
                <li class='faq-list__item'>Discuss future hopes and plans.</li>
                <li class='faq-list__item'>Discuss the arts and culture.</li>
                </ul>
                `;
const advanced = `
                <ul class='faq-list'>
                <li class='faq-list__item'>Express yourself with ease, spontaneity and effectiveness in social, academic and professional scenarios.</li>
                <li class='faq-list__item'>Make jokes and use double meanings or irony.</li>
                <li class='faq-list__item'>Be able to understand a wide variety of discourses: literary texts, films, scholarly articles, television, etc.</li>
                <li class='faq-list__item'>Produce clear speech, fluent and well-structured with a controlled use of organisational patterns, connectors and cohesive devices.</li>
                <li class='faq-list__item'>Speculate and use vague language.</li>
                <li class='faq-list__item'>Discuss controversy.</li>
                <li class='faq-list__item'>Use language of persuasion, frustration, criticism, evaluation, negotiation.</li>
                </ul>
                `;

const listC2 = `
                <ul class='faq-list'>
                    <li class='faq-list__item'>Take part in any conversation or discussion with ease.</li>
                    <li class='faq-list__item'>Be able to understand with ease virtually everything you hear or read.</li>
                    <li class='faq-list__item'>Use idiomatic expressions and colloquialisms.</li>
                    <li class='faq-list__item'>Express yourself fluently and accurately while conveying nuances.</li>
                    <li class='faq-list__item'>Learn to overcome communication problems with discretion.</li>
                </ul>
                `;

const faq2 = `
1. Rellenar el formulario<br/>
2. Nuestra coordinadora te llamará para confirmar tu nivel<br/>
3. ¡Empezar a estudiar!
`;

const tableData = {
  course: 'Mes',
  from: 'De',
  to: 'A',
  price: 'Precio',
  isSpanish: true,
};

const WithLocaleRedirect = (Component) => {
  const ComponentWithRedirect = (props) => {
    const handleRedirect = () => {
      window?.location.assign('/clases-de-ingles-economicas-barcelona');
    };

    return typeof window !== 'undefined' &&
      window.location.pathname?.includes('es/clases-de-ingles-economicas-barcelona') ? (
      <div className="classes-de-ingles-page classes-de-ingles-page--redirect">
        <div className="c-first-section c-first-section--md redirect-section">
          <div className="container">
            <h1 className="title">
              Conoce nuestros cursos{' '}
              <button className="red-link" onClick={handleRedirect}>
                aquí
              </button>
            </h1>
          </div>
        </div>
        <div className="second-section-redirect">
          <div className="container"></div>
        </div>
      </div>
    ) : (
      <Component {...props} />
    );
  };

  return (props) => (
    <Layout
      title={{ id: 'classesDeIngles.seo.title' }}
      description={{ id: 'classesDeIngles.seo.description' }}
    >
      <ComponentWithRedirect {...props} />
    </Layout>
  );
};

const ClassesDeIngles = ({ data }) => {
  const allPrismicCourses = data.allPrismicCourses.edges;
  const [isMore, setIsMore] = useState(false);

  return (
    <div className="classes-de-ingles-page">
      <section className="c-first-section c-first-section--sm">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-9 col-xl-7">
              <div className="c-first-section__schedule">
                <div className="item__wrapper">
                  <img src={iconCalendar} alt="2 días" />
                  <span className="c-text-14">2 días</span>
                </div>
                <div className="item__wrapper">
                  <img src={iconClock} alt="4 horas por semana" />
                  <span className="c-text-14">4 horas por semana</span>
                </div>
              </div>
              <h1 className="c-first-section__title">Clases De Inglés Económicas</h1>
              <p className="c-first-section__description">
                Aprende inglés estudiando 4 horas por semana.
              </p>
              <div className="c-first-section__btns">
                <a href="/es/apply/" className="c-btn c-btn--red">
                  Inscríbete ahora
                  <IconArrowRight />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="second-section c-section-py">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <h2 className="c-title-34 second-section__l">
                Mejora Tu Inglés Con Solo 4 Horas A La Semana
              </h2>
              <p className="c-text-18 description second-section__l">
                TEFL Iberia es un centro de formación de profesores de inglés y cada mes buscamos
                alumnos para que los profesores puedan realizar sus prácticas. En cada clase tendrás
                un equipo de 3 o 4 profesores guiados por un tutor experto, la mayoría de los
                profesores son nativos de todo el mundo y podrás disfrutar escuchando una variedad
                de acentos.
              </p>
              <div className="c-benefit-list second-section__l">
                <h4 className="c-text-16">Este curso es perfecto para ti si:</h4>
                <div className="benefit">
                  <img src={iconTick} alt="iconTick" />
                  <p className="c-text-16">
                    Disfrutas de conversar con gente de diferentes lugares y culturas.
                  </p>
                </div>
                <div className="benefit">
                  <img src={iconTick} alt="iconTick" />
                  <p className="c-text-16">Buscas un curso de excelente valor.</p>
                </div>
                <div className="benefit">
                  <img src={iconTick} alt="iconTick" />
                  <p className="c-text-16">
                    Tienes algo de conocimiento de inglés pero estás realmente interesado en
                    aprender y mejorar.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="wrapper">
                <div className="benefit-group">
                  <div className="group__img">
                    <img src={imgBenefit1} alt="Aprende en pequeños grupos" />
                  </div>
                  <div className="group__text">
                    <h3 className="c-title-18">Aprende en pequeños grupos</h3>
                    <p className="c-text-14">
                      Con un máximo de 10 estudiantes por clase, los profesores tienen tiempo para
                      dedicarse a todos.
                    </p>
                  </div>
                </div>
                <div className="benefit-group">
                  <div className="group__img">
                    <img src={imgBenefit2} alt="Sin tasas de matriculación ni cargos ocultos" />
                  </div>
                  <div className="group__text">
                    <h3 className="c-title-18">Sin tasas de matriculación ni cargos ocultos</h3>
                    <p className="c-text-14">
                      Somos transparentes con los costes, la lista de precios y el precio final es
                      lo mismo.
                    </p>
                  </div>
                </div>
                <div className="benefit-group">
                  <div className="group__img">
                    <img src={imgBenefit3} alt="Clases variadas y divertidas" />
                  </div>
                  <div className="group__text">
                    <h3 className="c-title-18">Clases variadas y divertidas</h3>
                    <p className="c-text-14">
                      Las clases comunicativas son muy divertidas además de ser una vía práctica y
                      rápida de aprender inglés.
                    </p>
                  </div>
                </div>
                <div className="benefit-group">
                  <div className="group__img">
                    <img src={imgBenefit5} alt="Una súper mezcla de culturas" />
                  </div>
                  <div className="group__text">
                    <h3 className="c-title-18">Una súper mezcla de culturas</h3>
                    <p className="c-text-14">
                      TEFL Iberia forma a profesores de todas partes del mundo, cada cual con una
                      cultura diversa que compartir.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="third-section c-section-includes c-section-py">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="block_l">
                <div className="block_l__img">
                  <img src={imgReview} alt="Ferran Ramírez" className="img-main" />
                  <img src={imgDots} alt="dots" className="dots" />
                </div>
                <div className="block_l__text">
                  <img src={iconQuotes} alt="quote" />
                  <p>
                    Actualmente soy alumno de esta escuela de idiomas y la recomiendo para todas las
                    personas que quieran practicar su inglés en un ambiente relajado, a un precio
                    muy económico. Me gusta mucho que los profesores le ponen mucho empeño y ganas a
                    cada clases y los temas son variados y de interés general. 100% recomendable!
                    <br />
                    <strong>Ferran Ramírez</strong>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="block_r">
                <h2 className="c-title-34">¿Qué se incluye?</h2>
                <div className="c-benefit-list block_r__benefits">
                  <div className="benefit">
                    <img src={iconTick} alt="iconTick" />
                    <p className="c-text-16">4 horas de clases por semana.</p>
                  </div>
                  <div className="benefit">
                    <img src={iconTick} alt="iconTick" />
                    <p className="c-text-16">Material de clase y matrícula.</p>
                  </div>
                  <div className="benefit">
                    <img src={iconTick} alt="iconTick" />
                    <p className="c-text-16">Profesores de inglés de todo el mundo.</p>
                  </div>
                  <div className="benefit">
                    <img src={iconTick} alt="iconTick" />
                    <p className="c-text-16">Clases muy variadas y bien preparadas.</p>
                  </div>
                  <div className="benefit">
                    <img src={iconTick} alt="iconTick" />
                    <p className="c-text-16">
                      Posibilidad de hacer clases particulares, sin ningún coste extra.
                    </p>
                  </div>
                </div>
                <div className="block_r__price block_r__price--full">
                  <h3 className="c-text-13">Precio final:</h3>
                  <h3 className="c-title-32">
                    50€ <span>/mes</span>
                  </h3>
                </div>
                <div className="block_r__price block_r__price--early-bird">
                  <h3 className="c-text-13">Para desempleados:</h3>
                  <h3 className="c-title-32">0€</h3>
                </div>
                <div className="block_r__btns">
                  <Link to="/es/apply/" className="c-btn c-btn--red">
                    Regístrate ahora
                    <IconArrowRight />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="fourth-section">
        <div className="container">
          <div className="fourth-section__top">
            <h2 className="c-title-34 top__title">Cursos y Fechas</h2>
            <div className="top__wrapper">
              <div className="item">
                <h4 className="c-text-16 item__title">A1 (básico)</h4>
                <p className="c-text-16 item__description">
                  09.30 a 11.30, martes, jueves y viernes
                </p>
                <p className="c-text-16 item__description">10.00 a 12.00, miércoles y viernes</p>
              </div>
              <div className="item">
                <h4 className="c-text-16 item__title">A2 (intermedio bajo)</h4>
                <p className="c-text-16 item__description">
                  09.30 a 11.30, lunes, miércoles, viernes
                </p>
                <p className="c-text-16 item__description">16.00 a 18.00, martes y jueves</p>
              </div>
              <div className="item">
                <h4 className="c-text-16 item__title">B1 (intermedio)</h4>
                <p className="c-text-16 item__description">10.00 a 12.00, martes y jueves</p>
                <p className="c-text-16 item__description">
                  16.00 a 18.00, lunes, miércoles, viernes
                </p>
              </div>
              <div className="item">
                <h4 className="c-text-16 item__title">B2 (intermedio alto)</h4>
                <p className="c-text-16 item__description">09.30 a 11.30, martes y jueves</p>
                <p className="c-text-16 item__description">
                  16.00 a 18.00, martes, jueves y viernes
                </p>
              </div>
            </div>
          </div>
          <ForthcomingCourses
            cardData={allPrismicCourses.slice(0, isMore ? allPrismicCourses.length : 4)}
            tableData={tableData}
            title={{ id: 'forthcomingCourses.title.upcoming' }}
          />
          {allPrismicCourses.length > 4 ? (
            <button
              onClick={() => setIsMore(!isMore)}
              className="c-btn c-btn--red-border load-more-dates"
            >
              {isMore ? 'Show Less Dates' : 'Load More Dates'}
              <IconArrowRightRed />
            </button>
          ) : null}
        </div>
      </section>

      <section className="fifth-section c-section-py">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-12">
              <div className="block_l">
                <h2 className="c-title-34 block_l__title">Prueba de nivel</h2>
                <p className="c-text-18 block_l__subtitle">
                  Nuestros cursos de inglés se centran en la comunicación, por eso es muy importante
                  hacer una prueba de nivel oral para asegurarnos que te incorporarás al curso que
                  realmente necesitas. Esta prueba dura 10 minutos y se realiza presencialmente o
                  por teléfono. Para coordinar tu prueba de nivel, completa{' '}
                  <Link to="/es/apply/" className="red-link">
                    este formulario
                  </Link>{' '}
                  y nuestra coordinadora te contactará para hacerla.
                </p>
              </div>
            </div>
            <div className="col-md-6 col-12">
              <div className="block_r">
                <h2 className="c-title-34 block_r__title">Curso gratuito</h2>
                <div className="c-benefit-list">
                  <div className="benefit">
                    <img src={iconTick2} alt="iconTick" />
                    <div className="benefit__wrapper-inner">
                      <h3 className="c-text-18">Cantidad limitada</h3>
                      <p className="c-text-16">
                        El cupo de cursos gratuitos para alumnos desempleados es limitado y se le da
                        prioridad a los primeros alumnos en apuntarse.
                      </p>
                    </div>
                  </div>
                  <div className="benefit">
                    <img src={iconTick2} alt="iconTick" />
                    <div className="benefit__wrapper-inner">
                      <h3 className="c-text-18">La confirmación con el DARDE</h3>
                      <p className="c-text-16">
                        Si te quieres beneficiar de las clase de inglés gratis hay que demostrarlo
                        con el DARDE, o cualquier documento que demuestre que no tienes trabajo.
                      </p>
                    </div>
                  </div>
                  <div className="benefit">
                    <img src={iconTick2} alt="iconTick" />
                    <div className="benefit__wrapper-inner">
                      <h3 className="c-text-18">Un mes gratis</h3>
                      <p className="c-text-16">
                        El primer mes es gratuito. Sin embargo, al principio del curso debes pagar
                        una fianza de 50€, la cual te la devolvemos en la última clase del mes.
                      </p>
                    </div>
                  </div>
                  <div className="benefit">
                    <img src={iconTick2} alt="iconTick" />
                    <div className="benefit__wrapper-inner">
                      <h3 className="c-text-18">Presencia obligatoria</h3>
                      <p className="c-text-16">
                        Los profesores están siendo evaluados para obtener el certificado oficial
                        TEFL como profesores de inglés. Por ello tu asistencia es muy importante y
                        por tanto obligatoria. Si pierdes 2 clases en un mes pierdes la fianza sin
                        derecho a reclamar independientemente del motivo. Si deseas más información,
                        escribe a ingles@tefl-iberia.com.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="eigth-section c-faqs-section">
        {/* <div className="wrapper c-section-py">
            <div className="container block_l">
              <div className="row no-gutters">
                <div className="col-xl-12 offset-xl-2">
                  <div className="content">
                    <h2 className="c-title-34">
                      Actualización <span> Covid-19</span>
                    </h2>
                    <p className="c-text-18">
                      En la escuela cumplimos con todas las medidas de seguridad necesarias para
                      garantizar la seguridad de docentes y alumnos (mascarillas, alcohol en gel,
                      limpieza de las instalaciones, ventilación y cupos limitados de alumnos por
                      curso).
                    </p>
                    <p className="c-text-18">
                      Pedimos a todos nuestros alumnos que asistan a clase con mascarilla y que
                      respeten las medidas de higiene y de distanciamiento necesarias. Las clases
                      son presenciales pero pueden cambiar a formato online de ser necesario por la
                      situación actual.
                    </p>
                  </div>
                  <div className="block_r">
                    <div className="block_r__img">
                      <img src={imgCovid} alt="imgCovid" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}

        <div className="container second-part">
          <div className="row">
            <div className="col-lg-6 col-12">
              <h3 className="c-title-34">Programa Académico</h3>
              <p className="c-text-18">
                Todos los cursos de inglés son de 4 horas por semana, pero los temas de estudio que
                ofrecen varían según tu nivel. Consulta la siguiente lista para ver qué aprenderás
                en cada nivel de lengua.
              </p>
            </div>
            <div className="col-lg-6 col-12">
              <Faq title="A1 Inicial" description={beginner} />
              <Faq title="A2 Intermedio Bajo" description={loverIntermediate} />
              <Faq title="B1 Intermedio" description={intermediate} />
              <Faq title="B2 Intermedio Alto" description={upperIntermediate} />
              <Faq title="C1 Avanzado" description={advanced} />
              <Faq title="C2 Proficient" description={listC2} />
            </div>
          </div>
        </div>
      </section>

      <section className="nineth-section">
        <ReviewsSection
          hideAllReviews
          description={{ id: 'graduates.teflSpanishCourses.description' }}
          title={{ id: 'graduates.spanish.title' }}
        />
      </section>

      <section className="tenth-section c-faqs-section">
        <div className="container">
          <div className="c-faqs-section__title">
            <h2 className="c-title-34">Preguntas Frecuentes</h2>
            <FormattedMessage id="url.courses.faq">
              {(locale) => (
                <Link to={locale[0]} className="c-btn c-btn--white">
                  <FormattedMessage id="btn.viewMoreFaqs" />
                  <IconArrowRightRed />
                </Link>
              )}
            </FormattedMessage>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <Faq description={faq2} title={faqs[0].title} />
              <Faq description={faqs[1].description} title={faqs[1].title} />
            </div>
            <div className="col-lg-6">
              <Faq description={faqs[2].description} title={faqs[2].title} />
              <Faq description={faqs[3].description} title={faqs[3].title} />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export const query = graphql`
  query {
    allPrismicCourses(
      filter: {
        data: { show_on_these_pages: { elemMatch: { page: { eq: "classes-de-ingles" } } } }
      }
      sort: { order: ASC, fields: data___from }
    ) {
      edges {
        node {
          id
          data {
            name {
              text
            }
            from(formatString: "D MMMM YYYY")
            to(formatString: "D MMMM YYYY")
            price
            is_price_per_month
            urgency_text {
              text
            }
          }
        }
      }
    }
  }
`;

export default WithLocaleRedirect(ClassesDeIngles);
